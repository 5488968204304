<template>
  <div class="detail-container">
    <div class="detail-wrapper">
      <div class="detail-tip">
        <tip-ete :title="title" />
      </div>
      <template v-if="isMultImage">
        <div class="swiper-out-wrapper">
          <swiper-ete
            v-if="detail.list && detail.list.length"
            :swiper-list="detail.list"
          />
        </div>
      </template>
      <template v-else>
        <img
          v-if="detail.list && detail.list.length"
          class="detail-img"
          :src="detail.list[0].images"
        />
        <div v-if="showDetails" class="detail-item">
          <!-- <div class="detail-title">{{ detail.title }}</div> -->
          <div class="detail-abs">{{ detail.abstract }}</div>
        </div>
      </template>

      <div class="des">
        <div v-if="detail.content" v-html="detail.content"></div>
      </div>
    </div>
  </div>
</template>
<script>
  import { getArticle } from '@/api/site'
  import { SetLogoPathNames } from '@/utils/index'
  export default {
    name: 'Detail',
    data() {
      return {
        id: '',
        title: '',
        isMultImage: false,
        showDetails: true,
        detail: {
          content: '',
          list: [],
          ArticleID: '',
          title: '',
          abstract: '',
        },
      }
    },
    async created() {
      let params = this.$route.query
      if (params) {
        this.id = params.id
        this.title = params.title
        if (this.title) document.title = this.title
        this.isMultImage = params.isMultImage == '1'
        this.showDetails = params.sd !== '1'
      }
      console.log(params, this.isMultImage)
      await this.getDetail()
    },
    methods: {
      async getDetail() {
        if (this.id) {
          let param = { ArticleID: this.id }
          var res = await getArticle(param)
          if (res.data) {
            let data = res.data
            this.detail.ArticleID = data.ArticleID
            this.detail.title = data.Title || ''
            this.detail.abstract = data.Abstract || ''
            if (data.Contents) {
              this.detail.content = data.Contents
            }
            if (data.LogoPath && data.OssImagePath) {
              this.detail.list = SetLogoPathNames(data)
            }
          }
        }
      },
    },
  }
</script>
<style lang="scss">
  // .detail-container {
  //   background: $base-color-background !important;
  //   .detail-wrapper {
  //     max-width: 1000px;
  //     // background: white;
  //     margin: 0 auto;
  //     text-align: left;
  //     padding: 15px;
  //     .detail-tip {
  //       margin: 20px 0px;
  //       text-align: center;
  //     }
  //     img.detail-img {
  //       width: 100%;
  //       max-width: 400px;
  //       // margin: 0 auto;
  //       display: block;
  //     }
  //     .detail-item {
  //       // text-align: center;
  //       .detail-title {
  //         font-size: 20px;
  //         margin-top: 20px;
  //       }
  //       .detail-abs {
  //         margin-top: 10px;
  //         font-size: 14px;
  //       }
  //     }
  //   }
  // }
</style>
