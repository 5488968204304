<template>
  <div class="content-container">
    <item-tip />
    <div class="content-wrapper">
      <tip-ete :title="title" />
      <div class="show-service">
        <div class="show-item">
          <div class="show-scroll">
            <div class="title"><span class="txt">推荐酒店</span></div>
            
            <div class="table-item">
              <table class="table-hotel">
                <tr>
                  <th>酒店名称</th>
                  <th>地址</th>
                  <th style="width: 200px">房型</th>
                  <th>价格</th>
                  <th>距离</th>
                  <th>联系人</th>
                </tr>
                <tr>
                  <td>杭州国际博览中心大酒店</td>
                  <td>杭州市萧山区宁围街道钱江世纪城奔竞大道353号</td>
                  <td>
                    大床房
                    <br />
                    双床标间
                  </td>
                  <td>550元/间/天（含双早）</td>
                  <td>0</td>
                  <td>预订部 0571-82908165</td>
                </tr>
                <tr>
                  <td>
                    杭州钱江世纪城
                    <br />
                    希尔顿欢朋酒店
                  </td>
                  <td>杭州市萧山区皓月路 189号诺德财富中心B座</td>
                  <td>
                    高级大床房
                    <br />
                    高级双床标间
                  </td>
                  <td>
                    450元/间/天（含双早）
                    <br />
                    480元/间/天（含双早）
                  </td>
                  <td>距离杭州国博1.3km</td>
                  <td>陈珍珍女士 18056958222</td>
                </tr>
                <tr>
                  <td>美豪丽致酒店奥体中心店</td>
                  <td>杭州市萧山区皓月路 20号明华国际</td>
                  <td>
                    丽致大床房
                    <br />
                    丽致双床标间
                    <br />
                    豪华大床房
                    <br />
                    豪华双床标间
                  </td>
                  <td>
                    420元/间/天（含双早）
                    <br />
                    480元/间/天（含双早）
                  </td>
                  <td>距离杭州国博1.7km</td>
                  <td>刘旋旋女士 13666603826</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="warn">
            上述酒店预留房间数量有限，请提早与酒店联系预定。
          </div>
        </div>

        <div class="show-item">
          <div class="show-scroll">
            <div class="title">
              <span class="txt">展会交通</span>
              <span class="subtxt">场馆周边公交、地铁及线路</span>
            </div>
            <div class="table-item">
              <table>
                <tr>
                  <th>目的地</th>
                  <th>线路</th>
                  <th>下车站名</th>
                  <th>距离（公里）</th>
                </tr>
                <tr>
                  <td rowspan="5">杭州国际博览中心</td>
                  <td>地铁6号线</td>
                  <td>博览中心（A1口）</td>
                  <td>0.5</td>
                </tr>
                <tr>
                  <td>公交519路</td>
                  <td>国际博览中心</td>
                  <td>0.1</td>
                </tr>
                <tr>
                  <td>机场巴士（湘湖线）</td>
                  <td>国际博览中心</td>
                  <td>0.1</td>
                </tr>
                <tr>
                  <td>公交581路</td>
                  <td>国际博览中心</td>
                  <td>0.1</td>
                </tr>
                <tr>
                  <td>公交788路（外环）</td>
                  <td>奔竞大道金鸡路口</td>
                  <td>0.7</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="show-item">
          <div class="show-scroll">
            <div class="title">
              <span class="txt">机场/火车站至场馆公共交通</span>
            </div>
          </div>
          
          <div class="table-item">
            <table class="table-hotel tdleft">
              <tr>
                <th>机场/火车站</th>
                <th>公共交通</th>
              </tr>
              <tr>
                <td>杭州萧山机场</td>
                <td>杭州国际博览中心距离 21 公里，乘坐出租车约 55 元。</td>
              </tr>
              <tr>
                <td>杭州东站</td>
                <td>
                  杭州国际博览中心地铁6号线至博览中心站，A1口出，从博览中心6号门进入，步行约5分钟。
                </td>
              </tr>
              <tr>
                <td>杭州站</td>
                <td>
                  杭州国际博览中心地铁1号线至江陵路站，换乘地铁6号线至博览中心站A1口出，从博览中心6号门进入，步行约5分钟。
                  <br />
                  地铁5号线至长河站，换乘地铁6号线至博览中心站A1口出，从博览中心6号门进入，步行约5分钟。
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  //import {GetArticleById} from '@/api/site'
  export default {
    name: 'Agenda',
    data() {
      return {
        showMore: false,
        agendaList: [],
        title:'展会服务',
        SType: 'ZHFWS',
        content:'',
        hotel_img:''
      }
    },
    async created() {
      //await this.getList()
    },
    methods: {
      async getList() {
        // var res= await GetArticleById({SType:this.SType})
        // console.log(res,'777777')
        // if(res.code==200){
        //   //this.hotel_img=res.data.OssImagePath+res.data.LogoPath
        //   this.content=res.data.Contents
        // }
      },
    },
  }
</script>
<style lang="scss">
  .tdleft {
    tr td {
      text-align: left;
    }
  }
  .show-service {
    .show-item {
      overflow-x: auto;
      .show-scroll {
        min-width: 768px;
      }
      .table-item {
        margin-top: $font20;

        table {
          width: 100%;
          border-collapse: collapse;
          tr {
            th {
              background: $base-color-red;
              color: #fff;
              font-size: $font15;
              padding: 15px;
            }
            td {
              background: #ffffff;
              color: #666666;
              font-size: $font15;
              border-right: 10px solid $base-color-background;
              border-top: 10px solid $base-color-background;
              padding: 15px;
              &:last-child {
                border-right: 0px;
              }
            }
          }
        }
        .table-hotel {
          tr td {
            border-right: 0px solid $base-color-background;
          }
        }
      }
      .title {
        margin-top: $font20;
        padding: 15px;
        background-color: $base-color-red;
        border-radius: 10px 10px 0px 0px;
        color: #ffffff;
        .txt {
          font-size: $font30;
          font-weight: 600;
        }
        .subtxt {
          font-size: $font20;
          font-weight: 400;
          color: #ffffff;
          margin-left: $font20;
        }
      }
      .warn {
        color: $base-color-red;
        font-size: $font20;
        margin-top: 10px;
        text-align: left;
      }
    }
  }
</style>
