<template>
  <div class="content-container">
    <item-tip />
    <div class="content-wrapper">
      <tip-ete :title="title" />
      <div class="show-service">
        <div class="show-item">
          <div v-html="content"></div>
        </div>

        <div class="show-item">
          <div v-html="content1"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { GetArticleById } from '@/api/site'
export default {
  name: 'Agenda',
  data() {
    return {
      showMore: false,
      agendaList: [],
      title: '展会服务',
      SType: 'ZHFWS',
      content: '',
      content1: '',
      hotel_img: ''
    }
  },
  async created() {
    await this.getList()
  },
  methods: {
    async getList() {
      var res = await GetArticleById({ SType: this.SType })
      if (res.code == 200) {
        //this.hotel_img=res.data.OssImagePath+res.data.LogoPath
        this.content = res.data.Contents
      }

      var res1 = await GetArticleById({ SType: "ZHFWB" })
      if (res1.code == 200) {
        //this.hotel_img=res.data.OssImagePath+res.data.LogoPath
        this.content1 = res1.data.Contents
      }
    },
  },
}
</script>
<style lang="scss">
.tdleft {
  tr td {
    text-align: left;
  }
}

.show-service {
  .show-item {
    overflow-x: auto;

    .show-scroll {
      min-width: 768px;
    }

    .table-item {
      margin-top: $font20;

      table {
        width: 100%;
        border-collapse: collapse;

        tr {
          th {
            background: $base-color-red;
            color: #fff;
            font-size: $font15;
            padding: 15px;
          }

          td {
            background: #ffffff;
            color: #666666;
            font-size: $font15;
            border-right: 10px solid $base-color-background;
            border-top: 10px solid $base-color-background;
            padding: 15px;

            &:last-child {
              border-right: 0px;
            }
          }
        }
      }

      .table-hotel {
        tr td {
          border-right: 0px solid $base-color-background;
        }
      }
    }

    .title {
      margin-top: $font20;
      padding: 15px;
      background-color: $base-color-red;
      border-radius: 10px 10px 0px 0px;
      color: #ffffff;

      .txt {
        font-size: $font30;
        font-weight: 600;
      }

      .subtxt {
        font-size: $font20;
        font-weight: 400;
        color: #ffffff;
        margin-left: $font20;
      }
    }

    .warn {
      color: $base-color-red;
      font-size: $font20;
      margin-top: 10px;
      text-align: left;
    }
  }
}
</style>
